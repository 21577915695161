
.team__content{
    text-align: center;
    margin-bottom: 50px;
}

.team__item{
    width: 100%;
    /* background: var(--team-card-bg); */
    /* background-color: rgb(213, 220, 220); */
    border-radius: 5px;
}

.subtitle{
    font-size: 28px;
    color: #151418;
    font-weight: 700;
    margin-bottom: 3.5rem
}

.team__img{
    width: 0fr;
    height: 200px;
    border-radius: 5px;
    /* background: white; */
    /* overflow: hidden; */
    /* padding-top: 10px; */
}

.team__img img{
    width: 100%;
    height: 180px;
    object-fit: contain;
    transform: scale(1.3);
}

.team__details{
    padding: 35px;
    align-self: auto;

}

.team__details h4{
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
}

h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 10px;
}

h2 {
    display: flex;
    align-items: left;
    justify-content: center;
    padding: auto;
    column-gap: 1rem;
    margin-top: 10px;
    font-size: 2.2rem;
}
p {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 10px;
}

.team__member-social {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 1rem;
    margin-top: 10px;

}

.team__member-social span i{
    padding: 5px;
    color: black;
    border-radius: 3px;
    cursor: pointer;
}

.team__wrapper {
    display: flex;
    align-items: center;
    column-gap: 0rem;
    /* padding-left: 3%;
    padding-right: 3%; */
}

@media only screen and (max-width: 768px) {
    .team__item {
        width: 30%;
        margin-bottom: 30px;
    }

    .team__wrapper {
        flex-wrap: wrap;
    }

    .team__content h2 {
        font-size: 1.5rem;
    }

    .team__details h4 {
        font-size: 1rem;
    }

    .team__details p {
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 576px) {
    .team__item{
        width: 100%;
    }
}